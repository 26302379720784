import React, { FC, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { toast } from 'react-toastify';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { ButtonMUI } from 'components/ButtonMUI';

import { useStore } from 'store';
import { SiteVariant } from 'utils';

import mainHeadset from 'assets/images/main_headset.mp4';
import mainVR from 'assets/images/main_vr.mp4';

import vrPoster from 'assets/images/vrPoster.png';
import somniumPoster from 'assets/images/somniumPoster.jpeg';

import somniumBanner from 'assets/images/somniumBanner.png';
import vrBanner from 'assets/images/vrBanner.png';
import welcomeBanner from 'assets/images/welcomeBanner.png';

import styles from './styles.module.scss';

export const AppContainer: FC = observer(({ children }) => {
  const { userStore, accountStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState<'left' | 'right' | null>(null);
  const [videoVisible, setVideoVisible] = useState({ left: false, right: false });
  const [showSelection, setShowSelection] = useState(true);

  const videoRefs = {
    left: useRef<HTMLVideoElement>(null),
    right: useRef<HTMLVideoElement>(null),
  };

  useEffect(() => {
    if (userStore.isAuthenticated) {
      getAccountInfo();
    }
  }, [userStore.isAuthenticated]);

  const getAccountInfo = async () => {
    try {
      const response = await accountStore.getAccount();
      accountStore.setAccount(response);
    } catch (e) {
      toast.warning('Error while getting account information');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setHovered(null);
      setVideoVisible({ left: false, right: false });

      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      });
    }

    return () => {
      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
        }
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    setShowSelection(location.pathname === '/');
  }, [location.pathname]);

  const handleHover = (side: 'left' | 'right') => {
    if (!showSelection) return;
    setHovered(side);
    setVideoVisible((prev) => ({ ...prev, [side]: true }));

    const video = videoRefs[side].current;
    if (video && video.readyState >= 3 && video.paused) {
      video.play().catch(() => {});
    }
  };

  const handleLeave = (side: 'left' | 'right') => {
    if (!showSelection) return;
    setHovered(null);

    setTimeout(() => {
      setVideoVisible((prev) => ({ ...prev, [side]: false }));

      const video = videoRefs[side].current;
      if (video && !video.paused) {
        video.pause();
      }
    }, 400);
  };

  useEffect(() => {
    return () => {
      Object.values(videoRefs).forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      });
    };
  }, []);

  const handleSelectVariant = (side: 'left' | 'right') => {
    const newVariant = side === 'left' ? SiteVariant.SOMNIUM : SiteVariant.VR;

    userStore.setSiteVariant(newVariant);
    setShowSelection(false);
  };

  return (
    <div className={cn(styles.container, { [styles.scrollable]: !showSelection })}>
      <Header />

      {showSelection ? (
        <div
          className={cn(styles.splitScreen, {
            [styles.hoveredLeft]: hovered === 'left',
            [styles.hoveredRight]: hovered === 'right',
          })}
        >
          <img
            src={welcomeBanner}
            alt="Welcome Banner"
            className={cn(styles.welcomeBanner, {
              [styles.hidden]: hovered !== null,
            })}
          />

          <div
            className={cn(styles.section, styles.left, {
              [styles.active]: hovered === 'left',
              [styles.inactive]: hovered === 'right',
            })}
            onMouseEnter={() => handleHover('left')}
            onMouseLeave={() => handleLeave('left')}
          >
            <img src={somniumBanner} alt="Somnium Banner" className={styles.bannerLeft} />

            <img
              src={somniumPoster}
              alt="Somnium Space"
              className={cn(styles.thumbnail, { [styles.hidden]: videoVisible.left })}
            />

            <video
              ref={videoRefs.left}
              src={mainVR}
              loop
              muted
              playsInline
              className={cn(styles.video, { [styles.visible]: videoVisible.left })}
            />

            <div className={cn(styles.hoverContentLeft, { [styles.visible]: hovered === 'left' })}>
              <div className={styles.row}>
                <ButtonMUI styleType="blue"
                   type="button"
                   className={styles.button}
                   onClick={() => handleSelectVariant('left')}
                   sx={{
                     '&&': {
                       padding: '5px 15px',
                       fontSize: '1.25rem',
                       height: '50px',
                       width: '305px',
                       marginBottom: '80px',
                       marginLeft: '40%',
                       background: 'rgba(25, 80, 125, 0.75)',
                       cursor: 'pointer !important'
                     }
                   }}>
                  Enter the Metaverse
                </ButtonMUI>
              </div>
              <h2 className={styles.title}>Somnium Space VR Platform</h2>
              <p className={styles.subtitle}>
                A persistent, open, and immersive Metaverse where you own, create, and explore.
              </p>
            </div>
          </div>

          <div
            className={cn(styles.section, styles.right, {
              [styles.active]: hovered === 'right',
              [styles.inactive]: hovered === 'left',
            })}
            onMouseEnter={() => handleHover('right')}
            onMouseLeave={() => handleLeave('right')}
          >
            <img src={vrBanner} alt="VR Banner" className={styles.bannerRight} />

            <img
              src={vrPoster}
              alt="VR Experience"
              className={cn(styles.thumbnail, { [styles.hidden]: videoVisible.right })}
            />

            <video
              ref={videoRefs.right}
              src={mainHeadset}
              loop
              muted
              playsInline
              className={cn(styles.video, { [styles.visible]: videoVisible.right })}
            />

            <div className={cn(styles.hoverContentRight, { [styles.visible]: hovered === 'right' })}>
              <div className={styles.row}>
                <ButtonMUI styleType="blue"
                   type="button"
                   className={styles.button}
                   onClick={() => handleSelectVariant('right')}
                   sx={{
                     '&&': {
                       padding: '5px 15px',
                       fontSize: '1.25rem',
                       height: '50px',
                       width: '305px',
                       marginBottom: '70px',
                       marginRight: '40%',
                       background: 'rgba(25, 80, 125, 0.75)',
                       cursor: 'pointer !important'
                     }
                   }}>
                  Discover VR1
                </ButtonMUI>
              </div>
              <h2 className={styles.title}>The First Somnium PC/VR Headset</h2>
              <p className={styles.subtitle}>
                Precision, immersion, and performance. The VR1 redefines the future of virtual reality.
              </p>
            </div>
          </div>
        </div>
      ) : (
        children
      )}

      <Footer />
    </div>
  );
});
